import { NewMaintenanceLog, columns } from "./table/columns";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { MaintenanceForm } from "./form/MaintenanceForm";
import { useToast } from "@/components/ui/use-toast"
import { formSchema } from "./form/MaintenanceForm";
import { z } from "zod";
import { Card, CardContent } from '@/components/ui/card';
import { Button } from "@/components/ui/button";
import { CalendarIcon } from "lucide-react";
import { SiteEventsTable } from "../siteEvents/table/table";
import {
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  PaginationState
} from "@tanstack/react-table"
import { supabase } from "@/lib/supabaseClient";
import { useSiteSelector } from "@/components/SiteProvider";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { Filter } from "../siteEvents/table/Filter";
import { DateRange } from "react-day-picker";
import moment from "moment";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { Calendar } from "@/components/ui/calendar";
import { Machine } from "../machines/list/table/columns";
import { useConfirmationDialog } from "@/components/confirmDialog/ConfirmDialogProvider";
import { fetchMaintenanceLogs, fetchMaintenanceStatuses, fetchMaintenanceSystems, fetchMaintenanceTypes } from "./queries";
import { fetchAllEquipment } from "../machines/queries";

export type FieldType = {
  id: string
  site_id: string
  name: string
}

const Maintenance = () => {
  const { selectedSite } = useSiteSelector();
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<NewMaintenanceLog[]>();
  const [equipment, setEquipment] = useState<Machine[]>();
  const [maintenanceTypes, setMaintenanceTypes] = useState<FieldType[]>();
  const [maintenanceStatuses, setMaintenanceStatuses] = useState<FieldType[]>();
  const [maintenanceSystems, setMaintenanceSystems] = useState<FieldType[]>();
  const [selectedSystemFilters, setSelectedSystemFilters] = useState<string[]>([]);
  const [selectedStatusFilters, setSelectedStatusFilters] = useState<string[]>([]);
  const [selectedTypeFilters, setSelectedTypeFilters] = useState<string[]>([]);
  const [selectedEquipmentFilters, setSelectedEquipmentFilters] = useState<string[]>([]);
  const [selectedLog, setSelectedLog] = useState<NewMaintenanceLog | undefined>(undefined);
  const [date, setDate] = useState<DateRange | undefined>()
  const { toast } = useToast();
  const { getConfirmation } = useConfirmationDialog();
  const [rowCount, setRowCount] = useState<number>(0);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })

  const onRowEdit = (log: NewMaintenanceLog) => {
    setSelectedLog(log)
    openForm();
  }

  const onRowDelete = async (log: NewMaintenanceLog) => {
    console.log('on row delete', log)
    const confirmed = await getConfirmation({
      title: "Delete Maintenance Log",
      message: "Are you sure you want to delete this maintenance log? This action cannot be undone.",
    });

    if (confirmed) {
      const { error } = await supabase
        .from('maintenance_logs')
        .delete()
        .eq('id', log.id)

      if (error) {
        alert(error.message);
      } else {
        toast({
          title: "Maintenance Log deleted",
        })

        if (!selectedSite) return;
        let { data, count } = await fetchMaintenanceLogs(selectedSite.id, pagination, selectedStatusFilters, selectedSystemFilters, selectedTypeFilters, selectedEquipmentFilters, date);
        if (data) {
          setData(data);
        }
        if (count) {
          setRowCount(count);
        }
      }
    }
  }

  const table = useReactTable({
    data: data || [],
    columns: columns({ onEdit: onRowEdit, onDelete: onRowDelete }),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      pagination
    },
    rowCount: rowCount,
    manualPagination: true,
    onPaginationChange: setPagination,
    initialState: {
      pagination: {
        pageSize: pagination.pageSize,
      },
      columnVisibility: {
        'id': false,
        'equipment_id': false,
        'time_down': false,
        'time_up': false,
      }
    }
  })

  useEffect(() => {
    if (!selectedSite) return;

    const getEquipment = async () => {
      const { data, error } = await fetchAllEquipment(selectedSite.id);
      if (data) {
        setEquipment(data);
      }
      if (error) {
        alert(error.message)
      }
    }

    getEquipment();
  }, [selectedSite])

  useEffect(() => {
    if (!selectedSite) return;

    Promise.all([
      fetchMaintenanceStatuses(selectedSite.id),
      fetchMaintenanceSystems(selectedSite.id),
      fetchMaintenanceTypes(selectedSite.id),
    ]).then(values => {
      setMaintenanceStatuses(values[0].data || undefined);
      setMaintenanceSystems(values[1].data || undefined);
      setMaintenanceTypes(values[2].data || undefined);
    });
  }, [selectedSite])

  useEffect(() => {
    if (!selectedSite) return;

    const getLogs = async () => {
      let { data, count, error } = await fetchMaintenanceLogs(selectedSite.id, pagination, selectedStatusFilters, selectedSystemFilters, selectedTypeFilters, selectedEquipmentFilters, date);
      if (data) {
        setData(data);
      }
      if (count) {
        setRowCount(count);
      }
      if (error) {
        alert(error.message)
      }
    }

    getLogs();
  }, [selectedSite, pagination, selectedSystemFilters, selectedStatusFilters, selectedTypeFilters, selectedEquipmentFilters, date]);

  const openForm = () => setOpen(true);

  const closeForm = () => {
    setOpen(false)
    setSelectedLog(undefined);
  };

  const onFormSuccess = async (values: z.infer<typeof formSchema>) => {
    const payload = {
      id: values.id,
      equipment_id: values.equipment_id,
      site_id: selectedSite?.id,
      work_order: values.work_order,
      work_order_description: values.work_order_description,
      description: values.description,
      system_id: values.system_id || null,
      downtime_type_id: values.downtime_type_id,
      status_id: values.status_id,
      smu: values.smu,
      date_down: values.date_down ? moment(values.date_down).format('YYYY-MM-DD') : null,
      time_down: values.time_down ? moment(values.time_down, 'hh:mm A').format('HH:mm') : null,
      date_up: values.date_up ? moment(values.date_up).format('YYYY-MM-DD') : null,
      time_up: values.time_up ? moment(values.time_up, 'hh:mm A').format('HH:mm') : null
    }
    const { data, error } = await supabase
      .from('maintenance_logs')
      .upsert(payload)
      .select()
      .single()

    if (error) {
      alert(error.message)
    }

    if (data) {
      let { data: logData, count, error: logError } = await fetchMaintenanceLogs(selectedSite?.id, pagination, selectedStatusFilters, selectedSystemFilters, selectedTypeFilters, selectedEquipmentFilters, date);
      if (logData) {
        setData(logData);
        setOpen(false);
        setSelectedLog(undefined);
        toast({
          title: `Maintanence log was ${values.id ? "Updated" : "Created"}`,
        })
      }
      if (logError) {
        alert(logError.message);
      }
      if (count) {
        setRowCount(count);
      }
    }
  }

  const handleSystemFilterChange = (value: string) => {
    if (selectedSystemFilters.includes(value)) {
      setSelectedSystemFilters(selectedSystemFilters.filter(s => s !== value));
    } else {
      setSelectedSystemFilters([...selectedSystemFilters, value]);
    }

    setPagination({
      pageIndex: 0,
      pageSize: pagination.pageSize
    })
  }

  const handleStatusFilterChange = (value: string) => {
    if (selectedStatusFilters.includes(value)) {
      setSelectedStatusFilters(selectedStatusFilters.filter(s => s !== value));
    } else {
      setSelectedStatusFilters([...selectedStatusFilters, value]);
    }

    setPagination({
      pageIndex: 0,
      pageSize: pagination.pageSize
    })
  }

  const handleTypeFilterChange = (value: string) => {
    if (selectedTypeFilters.includes(value)) {
      setSelectedTypeFilters(selectedTypeFilters.filter(s => s !== value));
    } else {
      setSelectedTypeFilters([...selectedTypeFilters, value]);
    }

    setPagination({
      pageIndex: 0,
      pageSize: pagination.pageSize
    })
  }

  const handleEquipmentFilterChange = (value: string) => {
    if (selectedEquipmentFilters.includes(value)) {
      setSelectedEquipmentFilters(selectedEquipmentFilters.filter(s => s !== value));
    } else {
      setSelectedEquipmentFilters([...selectedEquipmentFilters, value]);
    }

    setPagination({
      pageIndex: 0,
      pageSize: pagination.pageSize
    })
  }

  return (
    <div>
      {(!data || !table) &&
        <Card className="flex-1">
          <CardContent className="pt-6">
            <div className="flex gap-2 items-center justify-center rounded-md border py-80">
              <LoadingSpinner />
              <p className="font-semibold">Fetching Maintenance Logs...</p>
            </div>
          </CardContent>
        </Card>
      }

      {data && table && maintenanceTypes && maintenanceStatuses && maintenanceSystems &&
        <>
          <div className="flex mb-4 items-center gap-2">
            {equipment &&
              <Filter
                title="Equipment"
                options={equipment.map(item => ({ label: item.site_id, value: item.id as string }))}
                onFilterChange={handleEquipmentFilterChange}
                selectedValues={selectedEquipmentFilters}
              />
            }
            <Filter
              title="Systems"
              options={maintenanceSystems.map(item => ({ label: item.name, value: item.id }))}
              onFilterChange={handleSystemFilterChange}
              selectedValues={selectedSystemFilters}
            />
            <Filter
              title="Status"
              options={maintenanceStatuses.map(item => ({ label: item.name, value: item.id }))}
              onFilterChange={handleStatusFilterChange}
              selectedValues={selectedStatusFilters}
            />
            <Filter
              title="Downtime Type"
              options={maintenanceTypes.map(item => ({ label: item.name, value: item.id }))}
              onFilterChange={handleTypeFilterChange}
              selectedValues={selectedTypeFilters}
            />
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  id="date"
                  variant={"outline"}
                  className={cn(
                    "justify-start text-left font-normal",
                    !date && "text-muted-foreground"
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {date?.from ? (
                    date.to ? (
                      <>
                        {moment(date.from).format("MMM Do YY")} -{" "}
                        {moment(date.to).format("MMM Do YY")}
                      </>
                    ) : (
                      moment(date.from).format("MMM Do YY")
                    )
                  ) : (
                    <span>Pick a date</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  initialFocus
                  mode="range"
                  defaultMonth={date?.from}
                  selected={date}
                  onSelect={setDate}
                  numberOfMonths={2}
                />
              </PopoverContent>
            </Popover>

            <div className="ml-auto flex items-center gap-2">
              <Button className="ml-auto" onClick={openForm}>Add Maintenance</Button>
            </div>
          </div>

          <SiteEventsTable
            table={table}
          />

          <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="max-w-xl">
              <DialogHeader>
                <DialogTitle>{selectedLog ? "Edit" : "New"} Maintenance Log</DialogTitle>
              </DialogHeader>
              <MaintenanceForm
                onClose={closeForm}
                onFormSuccess={onFormSuccess}
                maintenanceLog={selectedLog}
                types={maintenanceTypes}
                statuses={maintenanceStatuses}
                systems={maintenanceSystems}
                equipment={equipment || []}
              />
            </DialogContent>
          </Dialog>
        </>
      }
    </div >
  )
}

export default Maintenance;