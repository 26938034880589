import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import Maintenance from "./Maintenance";
import Analytics from "./Analytics";
import { useLocation, useNavigate } from "react-router-dom";

const MaintanenceTab = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentTab = location.pathname.endsWith("/insights") ? "insights" : "logs";

  const handleTabChange = (value: string) => {
    navigate(value === "insights" ? "/maintenance/insights" : "/maintenance");
  };

  return (
    <div>
      <Tabs value={currentTab} onValueChange={handleTabChange}>
        <TabsList className="w-full bg-card h-[40px] border-b">
          <TabsTrigger value="logs">Maintenance Logs</TabsTrigger>
          <TabsTrigger value="insights">Insights</TabsTrigger>
        </TabsList>
        <div>
          <TabsContent value="logs">
            <Maintenance />
          </TabsContent>
          <TabsContent value="insights">
            <Analytics />
          </TabsContent>
        </div>
      </Tabs>
    </div>
  )
}

export default MaintanenceTab;