import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { ScrollArea } from "@/components/ui/scroll-area";
import { supabase } from "@/lib/supabaseClient";
import { CircleX } from "lucide-react";
import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form"
import { useSiteSelector } from "@/components/SiteProvider";
import { FieldType } from "../Maintenance";
import { OrganizationSite } from "@/pages/settings/queries";
import { fetchMaintenanceStatuses } from "../queries";

export const formSchema = z.object({
  name: z.string()
});

const StatusConfig = ({ site }: { site?: OrganizationSite }) => {
  const { selectedSite } = useSiteSelector();
  const [statuses, setStatuses] = useState<FieldType[]>();

  useEffect(() => {
    if (!selectedSite) return;
    const siteId = site ? site.id : selectedSite.id;

    const fetchSiteEvent = async () => {
      let { data, error } = await fetchMaintenanceStatuses(siteId)

      if (data) {
        setStatuses(data);
      }

      if (error) {
        alert(error.message);
      }
    }

    fetchSiteEvent();
  }, [selectedSite, site])

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema)
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    if (!selectedSite) return;
    const { data, error } = await supabase
      .from('maintenance_statuses')
      .insert({
        name: values.name,
        site_id: selectedSite.id
      })
      .select()
      .single();

    if (data) {
      setStatuses([...statuses || [], data]);
    }

    if (error) {
      alert(error.message);
    }
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Maintenance Statuses</CardTitle>
        <CardDescription>Status that represent a stage in maintenance log</CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col gap-4">
        {!statuses &&
          <div className="flex gap-2 items-center justify-center rounded-md border py-8">
            <LoadingSpinner />
            <p className="font-semibold">Fetching Maintenance Statuses...</p>
          </div>
        }

        {statuses &&
          <>
            <div>
              <Form {...form}>
                <form className="w-full flex">
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem className="flex-grow">
                        <FormControl>
                          <Input placeholder="Add a new status.." {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Button type="submit" onClick={form.handleSubmit(onSubmit)}>Add</Button>
                </form>
              </Form>
            </div>
            <div>
              <ScrollArea className="rounded-sm border">
                <ul>
                  {statuses.map(status => (
                    <li key={status.id} className="border-b px-2 flex items-center text-sm py-1">
                      {status.name}
                    </li>
                  ))}
                </ul>
              </ScrollArea>
            </div>
          </>
        }
      </CardContent>
    </Card>
  )
}

export default StatusConfig;